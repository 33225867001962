import React, { useContext, useEffect, useRef, useState } from "react";
import ModalComponent from "../modal";
import { StateContext } from "../../context";
import { addConfirmation, updateConfirmation } from "../../pages/apliecinajumi/api";
import CheckBox from "../../common/checkbox";
import Loader from "../../common/loader";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { useQuery } from "@tanstack/react-query";
import { getCms, postCms } from "../../classes/cmsApi";
import { usr } from "../../classes/user";

const IeladesModal = ({ trigger, callback }) => {
  const { modalSettings, handleModalClose } = useContext(StateContext);
  const { props } = modalSettings;

  const [data, setData] = useState({
    file: null,
    status: true,
  });

  const [loading, setLoading] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  const handleUpload = async () => {
    setLoading(true);
    try {
      const res = props
        ? await updateConfirmation(props, data?.file, data?.status)
        : await addConfirmation(data?.file, data?.status);
      if (res.error) {
        setUploadFailed(true);
      }
      setUploadFailed(false);
      callback();
      handleModalClose("ielades");
    } catch (error) {
      setUploadFailed(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setData({
      file: { name: props?.term_title } ?? null,
      status: props?.status ?? true,
    });

    setUploadFailed(false);
  }, [modalSettings]);

  const buttonEnabled = () => {
    if (props) {
      return data?.file || data?.status !== props?.status;
    }
    return !!data?.file?.name;
  };
  return (
    <ModalComponent
      id="ielades"
      trigger={trigger}
      title={props ? props?.term_title : t("popupMessageNewDeclaration")}
    >
      {!props && <InfoContainer />}
      <FileUpload uploadFailed={uploadFailed} data={data} setData={setData} props={props} />

      {uploadFailed && (
        <div className="authorize-main-left-alert" style={{ marginBottom: 10, marginTop: 10 }}>
          <div className="authorize-main-left-alert-text">{t("failedToUploadFile")}</div>
          <img
            onClick={() => setUploadFailed(false)}
            alt="alt"
            className="authorize-main-left-alert-icon"
            src="/assets/img/cancel3.svg"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setUploadFailed(false);
              }
            }}
          />
        </div>
      )}

      {loading ? (
        <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: 10 }}>
          <Loader />
        </div>
      ) : (
        <button
          disabled={!buttonEnabled()}
          className={`focus-red ielades-dialog-content-sub2-button${buttonEnabled() ? "-enabled" : "-disabled"}`}
          onClick={handleUpload}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleUpload();
            }
          }}
        >
          <Trans i18nKey="datasetAddSave"></Trans>
        </button>
      )}
    </ModalComponent>
  );
};

export default IeladesModal;

const InfoContainer = () => {
  const navigate = useNavigate();
  const { handleModalClose } = useContext(StateContext);

  return (
    <>
      <div className="ielades-dialog-content-sub1">
        <img alt="alt" className="ielades-dialog-content-sub1-icon1" src="/assets/img/check.svg" />
        <p className="ielades-dialog-content-sub1-font2">
          <Trans i18nKey="popupMessageWeCreatedDeclaration"></Trans>
        </p>
      </div>
      <div className="ielades-dialog-content-sub2-font3">
        <p>
          <Trans i18nKey="chooseTheAppropriteDeclaration"></Trans>
        </p>
      </div>

      <Templates />

      <div className="ielades-dialog-content-sub2-font3">
        <p>
          <Trans i18nKey="signAndUploadTheChosenDeclaration"></Trans>

          <span
            className="ielades-dialog-content-sub2-font3-detail"
            onClick={() => {
              handleModalClose("ielades");
              navigate("/palidziba");
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleModalClose("ielades");
                navigate("/palidziba");
              }
            }}
          >
            <Trans i18nKey="helpTab"></Trans>
          </span>
          <img alt="alt" src="/assets/img/link.svg" />
        </p>
      </div>
    </>
  );
};

const FileUpload = ({ data, setData, uploadFailed, props }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setData({
        ...data,
        file: selectedFile,
        status: true,
      });
    }
  };

  const handleStatusChange = () => {
    setData({
      ...data,
      status: !data.status,
    });
  };

  return (
    <div
      className="ielades-dialog-content-sub2-downloadgroup2"
      style={{ marginBottom: uploadFailed ? 10 : 30 }}
    >
      <span className="ielades-dialog-content-sub2-downloadgroup2-text">
        <Trans i18nKey="uploadElectornicallySignedDocument"></Trans>
      </span>
      <div className="ielades-dialog-content-sub2-downloadgroup2-group">
        <button
          className="ielades-dialog-content-sub2-downloadgroup2-group-button focus-red"
          onClick={handleFileChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleFileChange();
            }
          }}
        >
          <img alt="alt" src="/assets/img/upload.svg" />
          <Trans i18nKey="popupMessageAddFile"></Trans>
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: "none" }}
          accept=".edoc, .pdf"
        />
        <span className="ielades-dialog-content-sub2-downloadgroup2-group-text">
          {data?.file ? data?.file.name : t("popupMessageFileNotAdded")}
        </span>
        <p className="ielades-dialog-content-sub2-font3">[Max: 60MB]</p>
      </div>
      {props && (
        <div className="ielades-dialog-content-sub2-downloadgroup2-group1">
          <span className="ielades-dialog-content-sub2-downloadgroup2-group1-text">
            Apliecinājums spēkā no:
          </span>
          <div className="ielades-dialog-content-sub2-downloadgroup2-group1-box">
            <img
              alt="alt"
              className="ielades-dialog-content-sub2-downloadgroup2-group1-box-img"
              src="/assets/img/calendar.svg"
            />
            <span
              className="ielades-dialog-content-sub2-downloadgroup2-group1-box-text"
              style={{ cursor: "default" }}
            >
              {props?.creation_time.slice(0, 10)}
            </span>
          </div>
        </div>
      )}
      <CheckBox text={t("active")} checked={data.status} onClick={handleStatusChange} />
    </div>
  );
};

const getFiles = async () => {
  try {
    return await getCms("api/v1/get/confirmation-template/list");
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getUserData = () => {
  return {
    juridicalPersonName: usr.juridicalPersonName,
    juridicalPersonAddress: usr.juridicalPersonAddress,
    juridicalPersonRegNr: usr.juridicalPersonRegNr,
    firstName: usr.givenName,
    lastName: usr.familyName,
  };
};

// download docx file
const downloadFile = async (file_id, file_name) => {
  try {
    const res = await postCms(
      "api/v1/get/confirmation-template/download",
      { file_id, ...getUserData() },
      undefined,
      undefined,
      true
    );
    const url = URL.createObjectURL(res);
    const a = document.createElement("a");
    a.href = url;
    if (file_name?.includes(".")) {
      file_name += ".docx";
    }
    a.download = file_name;
    a.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  }
};

const Templates = () => {
  const { data: files } = useQuery({
    queryKey: "files",
    queryFn: getFiles,
    placeholderData: [],
    refetchOnWindowFocus: false,
  });

  return (
    <div className="ielades-dialog-content-sub2-downloadgroup1">
      {files.map((file) => (
        <div key={file.title} className="ielades-dialog-content-sub2-download">
          <div
            className="ielades-dialog-content-sub2-download-file"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                downloadFile(file.file_id, file.term_title);
              }
            }}
          >
            <img alt="alt" src="/assets/img/download.svg" />
            <u onClick={() => downloadFile(file.file_id, file.term_title)}>{file.term_title}</u>
          </div>
        </div>
      ))}
    </div>
  );
};
