import { useState } from "react";
import { useForm } from "react-hook-form";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import NotificationBanner from "../../../common/notification";
import { TermsOfUse } from "./termsOfUse";
const StepThree = ({ onClick }) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: `all` });


  const [rulesAccepted, setRulesAccepted] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="registration-TOU-container">
      <div className="register-info-right-header">
        <Trans i18nKey="registrationTOU"></Trans>
      </div>

      <TermsOfUse className={"register-info-right-rules-scrollbar focus-red"} setAccepted={setRulesAccepted} />

      <form onSubmit={handleSubmit(onClick)}>
        {!rulesAccepted && (
          <NotificationBanner text={t("registrationReadEntire")} backgroundColor="#56595a" canClose={true} />
        )}

        <button
          className={`register-info-right-next-profile-button${
            rulesAccepted && Object.keys(errors).length === 0 ? `` : `-disabled`
          } focus-red`}
          id="progress-next2-third"
          disabled={!rulesAccepted || Object.keys(errors).length}
          style={{
            height: 58,
            marginTop: 20,
            ...(rulesAccepted && Object.keys(errors).length === 0
              ? {}
              : { filter: `grayscale(1)`, cursor: `not-allowed` }),
          }}
        >
          <img alt="alt" src="/assets/img/next.svg" />
          <Trans i18nKey="registrationContinue"></Trans>
        </button>
      </form>
      <GoogleReCaptcha
        onVerify={(token) => {
          window.reCaptchaToken = token;
        }}
        refreshReCaptcha={refreshReCaptcha}
      />
    </div>
  );
};

export default StepThree;
