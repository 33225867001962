import React, { useCallback, useContext, useEffect, useState } from "react";

import Initial from "./components/initial";
import StepFive from "./components/stepfive";
import StepFour from "./components/stepfour";
import StepThree from "./components/stepthree";
import StepTwo from "./components/steptwo";
import AuthModal from "../../modals/authmodal";

import { useParams } from "react-router-dom";
import { Banner } from "../../common";
import { BACKEND_URL } from "../../classes/api";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { StateContext } from "../../context";

const Register = () => {
  const { t } = useTranslation();
  const params = useParams();

  const { language } = useContext(StateContext);

  const [banners, setBanners] = useState({});

  const [step, setStep] = useState(params.key ? 3 : 0);
  const [type, setType] = useState(0); // 0 - consumer, 1 - publisher
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (step !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [step]);

  const bannerData = {
    title: t("userRegistration"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("registrationCreateNewUser"),
  };

  const setPassword = useCallback((data) => {
    const key = params.key ?? null;
    if (key) {
      fetch(`${BACKEND_URL}/api/setPassword`, {
        method: `POST`,
        headers: {
          crossDomain: true,
          Accept: `application/json`,
          "Content-Type": `application/json`,
        },
        body: JSON.stringify({ key, ...data, token: window.reCaptchaToken }),
      })
        .then(async (data) => {
          const _data = await data.json();
          if (_data.ok) {
            setStep(5);
          } else {
            console.error(`Neizdevās saglabāt paroli.`);
          }
        })
        .catch((err) => {
          if (err.name === "SyntaxError") console.error("Unexpected api call return");
          else console.error(err);
        });
    }
  }, [params.key]);

  const checkEmail = useCallback((data) => {
    setBanners({});
    fetch(`${BACKEND_URL}/api/checkEmail`, {
      method: `POST`,
      headers: {
        crossDomain: true,
        Accept: `application/json`,
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ email: data.email, token: window.reCaptchaToken }),
    })
      .then(async (resp) => {
        const _data = await resp.json();
        if (_data.ok) {
          setUserData(data);
          setStep(2);
        } else {
          setBanners({
            ...banners,
            email: {
              message: t("registrationUserExists"),
              ref: data.email,
            },
          });
          window.scrollTo({ top: 100, behavior: "smooth" });
        }
      })
      .catch((err) => {
        if (err.name === "SyntaxError") console.error("Unexpected api call return");
        else console.error(err);
      });

  }, [setUserData, banners, t]);

  const registerUser = useCallback(() => {
    setBanners({});
    fetch(`${BACKEND_URL}/api/register`, {
      method: `POST`,
      headers: {
        crossDomain: true,
        Accept: `application/json`,
        "Content-Type": `application/json`,
      },
      body: JSON.stringify({ ...userData, type: type, token: window.reCaptchaToken }),
    })
      .then(async (data) => {
        const _data = await data.json();
        if (_data.ok) {
          setStep(3);
        } else {
          setBanners({
            ...banners,
            email: {
              message: t("registrationUserExists"),
              ref: userData.email,
            },
          });
        }
      })
      .catch((err) => {
        if (err.name === "SyntaxError") console.error("Unexpected api call return");
        else console.error(err);
      });
  }, [userData, type, banners, t]);

  useEffect(() => {
    if (banners.email) {
      setBanners({
        ...banners,
        email: {
          ...banners.email,
          message: t("registrationUserExists"),
        },
      });
    }
  }, [language]);
  

  return (
    <>
      <Banner bannerData={bannerData} />

      <div className="register">
        <div className="register-info w3-row">
          <div className="register-info-left w3-col">
            <div className="register-info-left-steps">
              {step !== 0 && <div id={`progress-bar${step > 0 ? "" : "-gray"}`}></div>}

              <div className="register-info-left-steps-item">
                <div className="register-info-left-steps-item-icon">
                  <div className="register-info-left-steps-item-icon-circle active">
                    <img alt="alt" src="/assets/img/first-step.svg" />
                  </div>
                </div>
                <div className="register-info-left-steps-item-label">
                  <span>
                    <b><Trans i18nKey="pathingUserType"></Trans></b>
                  </span>
                  <br />
                  <Trans i18nKey="registrationToStartRegistration"></Trans>
                </div>
              </div>
              {step !== 0 && (
                <>
                  <div id={`progress-bar${step > 0 ? "" : "-gray"}`}></div>

                  <div className="register-info-left-steps-item">
                    <div className={`register-info-left-steps-item-icon-circle${step > 0 ? " active" : ""}`}>
                      <span>1</span>
                    </div>
                    <div className="register-info-left-steps-item-label">
                      <span>
                        <b className="steps-label-header"><Trans i18nKey="registrationProfileInfo"></Trans></b>
                      </span>
                      <br />
                      <span className="steps-label-description">
                        <Trans i18nKey={type === 0 ? "registrationProvideEmail" : "registrationProvidePersonalInfo"} />
                      </span>
                    </div>
                  </div>
                  <div className="register-info-left-steps-item">
                    <div id={`progress-bar${step > 1 ? "" : "-gray"}`}></div>
                    <div className={`register-info-left-steps-item-icon-circle${step > 1 ? " active" : ""}`}>
                      <span>2</span>
                    </div>
                    <div className="register-info-left-steps-item-label">
                      <span>
                        <b className="steps-label-header"><Trans i18nKey="registrationTOU"></Trans></b>
                      </span>
                      <br />
                      <span className="steps-label-description">
                      <Trans i18nKey="registrationReadNPPRules"></Trans>
                                            </span>
                    </div>
                  </div>
                  <div className="register-info-left-steps-item">
                    <div id={`progress-bar${step > 2 ? "" : "-gray"}`}></div>
                    <div className={`register-info-left-steps-item-icon-circle${step > 2 ? " active" : ""}`}>
                      <span>3</span>
                    </div>
                    <div className="register-info-left-steps-item-label">
                      <span>
                        <b><Trans i18nKey="registrationConfirm"></Trans></b>
                      </span>
                      <br />
                      <Trans i18nKey="registrationWeWillSendLInk"></Trans>                    </div>
                  </div>
                  <div className="register-info-left-steps-item">
                    <div className={`register-info-left-steps-item-icon-circle${step > 3 ? " active" : ""}`}>
                      <span>4</span>
                    </div>
                    <div className="register-info-left-steps-item-label">
                      <span>
                        <b><Trans i18nKey="registrationCreatePassword"></Trans></b>
                      </span>
                      <br />
                      <Trans i18nKey="registrationProtectProfile"></Trans>                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {step === 0 && (
            <Initial
              clickConsumer={() => {
                setType(0);
                setStep(1);
                window.localStorage.type = 0;
              }}
              clickPublisher={() => {
                setType(1);
                setStep(1);
                window.localStorage.type = 1;
              }}
            />
          )}

          {step === 1 && (
            <StepTwo
              type={type}
              banners={banners}
              setBanners={setBanners}
              setData={checkEmail}
            />
          )}
          {step === 2 && (<StepThree onClick={registerUser} />)}
          {step === 3 && (
            <StepFour
              regKey={params.key}
              onClick={() => {
                setStep(4);
              }}
            />
          )}
          {step === 4 && (
            <StepFive
              onClick={(data) => {
                setPassword(data);
                setStep(5);
              }}
            />
          )}
          {step === 5 && (
            <div>
              <div className="register-info-right-header">
              <Trans i18nKey="registrationAccountCreated"></Trans>
              </div>

              <div className="register-info-right-description">
              <Trans i18nKey="registrationAuthNow"></Trans>
              </div>
              <br />
              <br />
              <AuthModal
                onEnterClick={() => {}}
                onRegisterCallback={() => {
                  setStep(0);
                  setType(null);
                }}
                trigger={
                  <button className="register-info-right-last-button focus-red" id="progress-next1-sixth" style={{ marginTop: "60px" }}>
                    <img alt="alt" src="/assets/img/auth.svg" />
                    <Trans i18nKey="authorize"></Trans>
                  </button>
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
