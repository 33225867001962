import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getCms } from "../../classes/cmsApi";
import { Banner } from "../../common";
import { AccordItem } from "./components";
import { useTranslation } from "react-i18next";
import { StateContext } from "../../context";
import { useParams, useSearchParams } from "react-router-dom";
import { usr as user } from "../../classes/user";
import useSuperDuperReliableSmoothScroll from "../../hooks/useSuperDuperReliableSmoothScroll";
import { useQuery } from "@tanstack/react-query";
import { ErrorMessage, ReloadPageButton } from "../manaskopas/components/errormessage";
import Loader from "../../common/loader";

const Accordination = ({ helpPages, tabOpened, helpOpened, setHelpOpened, setTabOpened }) => {
  return helpPages.map((item) => (
    <AccordItem
      key={item.id}
      item={item}
      tabOpened={tabOpened}
      helpOpened={helpOpened}
      setHelpOpened={setHelpOpened}
      onMenuItemClick={() => {
        // main accordion title click
        setTabOpened(tabOpened === item.id ? 0 : item.id);
      }}
    />
  ));
};

const Help = () => {
  const { nid } = useParams();
  const { t } = useTranslation();
  const [tabOpened, setTabOpened] = useState(1);
  const [helpOpened, setHelpOpened] = useState(Number(nid) || 0);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedBody, setSelectedBody] = useState("");
  const { language } = useContext(StateContext);
  const [searchParams] = useSearchParams();
  const currentPage = useMemo(() => searchParams.get("currentPage") || "", [searchParams]);
  const currentSubPage = useMemo(() => searchParams.get("subPage") || "", [searchParams]);

  const dashboardBanner = {
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
  };
  const bannerData = {
    title: t("help"),
    path1: t("workspaceStart"),
    path1Url: "/",
    ...(user.sub ? dashboardBanner : {}),
    path3: t("help"),
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await getCms("public/help-pages", language);
      const mapping = {};
      let foundCurr = false;
      for (const item of response) {
        const category = item.field_pamatkategorijas_nosaukums[0].value;
        const title = item.title[0].value;

        if (!foundCurr && helpOpened === 0 && (category === t(currentPage) || title === t(currentSubPage))) {
          foundCurr = true;
          setHelpOpened(item.nid[0]?.value);
        }
        if (item.nid[0]?.value === helpOpened || item.nid[0]?.value === Number(nid)) {
          setSelectedTitle(item.title[0]?.value);
          setSelectedBody(item.body[0]?.processed);
          const index = Object.keys(mapping).indexOf(category);
          setTabOpened(index > -1 ? index + 1 : Object.keys(mapping).length + 1);
        }

        if (!mapping[category]) {
          mapping[category] = [];
        }
        mapping[category].push(item);
      }
      const accordData = Object.keys(mapping).map((category, index) => {
        const dataMapping = {};
        let reorder = false;
        mapping[category].forEach((item) => {
          if (helpOpened === 0 && item?.field_noklusejuma_lapa[0]?.value === true && !nid) {
            if (!foundCurr) setHelpOpened(item.nid[0]?.value);
            setSelectedTitle(item.title[0]?.value);
            setSelectedBody(item.body[0]?.processed);
            setTabOpened(index + 1);
          }

          const title = item.field_palidzibas_lapas_nosaukums[0]?.value;
          if (!dataMapping[title]) {
            dataMapping[title] = {
              title,
              sub: [],
            };
          }

          if (item?.field_noklusejuma_lapa[0]?.value === true) {
            dataMapping[title].default = true;
            reorder = true;
          }

          if (item.field_apakskategorijas_nosaukums?.length > 0) {
            item.field_apakskategorijas_nosaukums.forEach((sub) => {
              dataMapping[title].sub.push({
                title: sub.value,
                pageTitle: item.title[0]?.value,
                pageBody: item.body[0]?.processed,
                nid: item.nid[0]?.value,
              });
            });
          } else {
            dataMapping[title].pageTitle = item.title[0]?.value;
            dataMapping[title].pageBody = item.body[0]?.processed;
            dataMapping[title].nid = item.nid[0]?.value;
          }
        });

        const data = Object.values(dataMapping);

        if (reorder) {
          const defaultPage = data.find((item) => item.default);
          data.splice(data.indexOf(defaultPage), 1);
          data.unshift(defaultPage);
        }

        return {
          id: index + 1,
          title: category,
          data,
        };
      });
      return accordData;
    } catch (error) {
      console.error(error);
    }
  }, [currentPage, currentSubPage, helpOpened, language, nid, t]);

  const {
    data: helpPages,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ["help-pages", language, currentPage, currentSubPage, helpOpened, nid],
    queryFn: fetchData,
    placeholderData: [],
    refetchOnWindowFocus: false,
  });

  useSuperDuperReliableSmoothScroll([helpOpened, tabOpened]);

  if (isLoading || isFetching) return <Loader />;
  if (isError)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <ErrorMessage text={error.error ?? t("dataFailedToLoad")} />
        <ReloadPageButton />
      </div>
    );

  return (
    <>
      <Banner bannerData={bannerData} />
      <div className="palidzibas-main">
        <div>
          <div className="palidzibas-main-accordion">
            <Accordination
              helpPages={helpPages}
              tabOpened={tabOpened}
              helpOpened={helpOpened}
              setHelpOpened={setHelpOpened}
              setTabOpened={setTabOpened}
            />
          </div>
        </div>
        <div>
          <div className="palidzibas-main-right">
            <div className="palidzibas-main-right-width">
              <span className="palidzibas-main-right-width-font1">{selectedTitle}</span>
              <div dangerouslySetInnerHTML={{ __html: selectedBody }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
