import moment from "moment";
import MetadataRows from "./MetadataRows";
import { useContext } from "react";
import { StateContext } from "../../../context";
import { useTranslation } from 'react-i18next';

const BasicInfoTable = ({ data, openTab }) => {
  const { t } = useTranslation();
  const { language } = useContext(StateContext);
  if (!Object.keys(data).length) return null;
  // TODO: change titles by passing a json file to language context's "languageList"

  const renderedData = [
    {
      title: t("datasetAddDate"),
      fields: [
        (data.field_metadata_date && moment(data.field_metadata_date).format("DD.MM.YYYY HH:mm")) ?? "",
      ],
      extra: "date",
    },
    {
      title: t("datasetAddUpdated"),
      fields: [data.field_metadata_change_notes ?? ""],
      extra: null,
    },
    {
      title: language === t("datasetServiceType"),
      fields: [
        language === data.field_service_type ?? "",
      ],
      extra: null,
    },
    {
      title: t("datasetAddDataOwner"),
      fields: [data.field_data_owner_name ?? ""],
      extra: null,
    },
    {
      title: t("datasetAddDataGiverWebsite"),
      fields: [],
      extra: null,
      navigateTo: data.field_publisher_website,
      navigateToText: data.field_publisher_website,
    },
    {
      title: "Publicēt no",
      fields: [data.field_published_from ?? ""],
      extra: null,
    },
    {
      title: t("validFrom"),
      fields: [(data.field_valid_from && moment(data.field_valid_from).format("DD.MM.YYYY HH:mm")) ?? ""],
      extra: "date",
    },
    {
      title: t("validUntil"),
      fields: [(data.field_valid_to && moment(data.field_valid_to).format("DD.MM.YYYY HH:mm")) ?? ""],
      extra: "date",
    },
    {
      title: t("datasetNationalAssessmentDate"),
      fields: [(data.field_national_assessment_date && moment(data.field_national_assessment_date).format("DD.MM.YYYY HH:mm")) ?? ""],
      extra: "date",
    },
    {
      title: t("datasetAddGeographicalCoverageCountry"),
      fields: [t("latvia")],
      extra: null,
    },
    {
      title: t("datasetAddGeographicalCoverage"),
      fields: [data.field_geographic_coverage_nuts ?? ""],
      extra: null,
    },
    {
      title: t("datasetCoordinateSystem"),
      fields: [data.field_coordinate_system ?? ""],
      extra: null,
    },
    {
      title: t("datasetGeoreferencing"),
      fields: [data.field_georeferencing_method ?? ""],
      extra: null,
    },
    {
      title:  t("datasetAddTransportNetworkCoverage"),
      fields: [data.field_transport_network_coverage ?? ""],
      extra: null,
    },
    {
      title: t("datasetAddQualityDescription"),
      fields: [data.field_quality_level ?? ""],
      extra: null,
    },
    {
      title: t("dataseteAddStandardLicence"),
      fields: [data.field_licence_conditions ?? ""],
      extra: null,
    },
    {
      title: t("datasetAddPublicationType"),
      fields: [data.field_distribution_mode ?? ""],
      extra: null,
    },
    {
      title: t("popupMessageReadyToDownload"),
      fields: [data.field_downloadable ?? ""],
      extra: null,
    },
    {
      title: t("datasetAddDataFormat"),
      fields: [
        data.field_format_model ?? "",
      ],
      extra: null,
    },
    // {
    //   title: t("datasetAddDataSyntax"),
    //   fields: [
    //     data.field_field_data_format_syntax ?? "",
    //   ],
    //   extra: null,
    // },
    {
      title: t("datasetAddDataSchema"),
      fields: [
        data.field_data_format_schema ?? "",
      ],
      extra: null,
    },
    {
      title: t("datasetDatexPublicationClass"),
      fields: [
        data.field_datex_publication_type ?? "",
      ],
      navigateTo: "/palidziba?subPage=datexSchemasHelpPage",
      navigateToText: t("datexSchemaHelp"),
      extra: null,
    },
    {
      title: t("datasetAddAccessProtocol"),
      fields: [data.field_access_interface ?? ""],
      extra: null,
    },
    {
      title: t("datasetAddCommunicationMethod"),
      fields: [data.field_communication_method ?? ""],
      extra: null,
    },
  ].filter((item) => {
      return item?.fields?.[0] || item?.navigateTo;
  });
 
  // LVC-2524 7 punkts
  const tableStyle = {
    paddingTop: 39,
    paddingBottom: 29,
    width: "100%",
    tableLayout: "fixed",
  };

  return (
    <table
      className="card-content-table"
      style={tableStyle}
    >
      <tbody>
        <MetadataRows data={renderedData} openTab={openTab} />
      </tbody>
    </table>
  );
};

export default BasicInfoTable;
