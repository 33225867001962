import React, { useState } from 'react';

const NotificationBanner = ({
  svg, // warn alert
  text = "Šāds lietotājs neeksistē!",
  backgroundColor = "#33D751", // #33D751 #9e313a #00CCF5 #56595a
  canClose = true,
  style = {},
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const backgroundColorNameMap = {
    "#33d751": "green",
    "#9e313a": "red",
    "#00ccf5": "blue",
    "#56595a": "dark-grey",
    "#4caf50": "green",
    "#9f1b33": "dark-red",
  };
  
  const backgroundColorName = backgroundColorNameMap[backgroundColor.toLowerCase()];

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      className={`authorize-main-left-alert authorize-main-left-alert-${backgroundColorName}`}
      style={{ ...style }}
    >
      <div className="alert-main">
        {svg && (
          <img
            alt="alt"
            className="alert-main-icon"
            style={{ marginRight: 28 }}
            src={`/assets/img/${svg}.svg`}
          />
        )}
        <div className="authorize-main-left-alert-text">{text}</div>
      </div>
      {canClose && (
        <img
          onClick={handleClose}
          alt="alt"
          className="authorize-main-left-alert-icon"
          src="/assets/img/Cancel.svg"
        />
      )}
    </div>
  );
};

export default NotificationBanner;