import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "./atoms/secondarybutton";
import { get } from "../classes/api";
import { Trans } from 'react-i18next';
import { t } from "i18next";

const DataProvide = ({ title, btn, limit }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the data from API here
    get("/api/v1/fetch/dd", limit && [{ key: "limit", value: limit }])
      .then((resp) => {
        const fetchedData = resp.data;
        // Do with the fetched data as thou wilt. Here I set it into state.
        setData(fetchedData);
      })
      .catch((err) => console.error(err));
  }, [limit]);

  return data.length > 0 ? (
    <div className="data-provide">
      {title && <div className="data-provide-title">{title}</div>}
      {data.length !== 0 && (
        <div className="data-provide-content">
          {(limit ? data.slice(0, limit) : data).map((item, index) => (
            <DataItem item={item} navigate={navigate} key={index} btn={btn} />
          ))}
          {title && (
            <div className="data-provide-content-item" onClick={() => navigate("/sniedzeji")}>
              <div className="data-provide-content-item-background">
                <div
                  className="data-provide-content-item-background-text"
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if(event.key === "Enter"){
                      navigate("/sniedzeji");
                    }
                  }}
                >
                <Trans i18nKey="homeAllDataGivers"></Trans>
                  <img
                    alt="alt"
                    className="data-provide-content-item-background-text-icon"
                    src="/assets/img/next.svg"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    ""
  );
};

//Šeit vajadzēs pielikt to kataloga filtru
const DataItem = ({ item, navigate, btn }) => {
  return (
    <div className="data-provide-content-item1">
      <div className="data-provide-content-item1-title">
        <img alt="alt" className={"data-provide-content-item1-title-icon"} src={item.img} />
      </div>
      <div className="data-provide-content-item1-description">{item.title}</div>
      <div className="data-provide-content-item1-link">
        <a href={`${item.href}`} target="_blank" rel="noreferrer">
          {item.href}
        </a>
        <img alt="alt" src="/assets/img/link.svg" />
      </div>
      {btn && (
        <div>
          <SecondaryButton
            onClick={() => navigate(`/catalog?${new URLSearchParams({ dataGiver: item.id })}`)}
            title={t("dataInCatalog")}
            style={{ marginTop: "2rem" }}
            svg="next"
            invert
            big
          />
        </div>
      )}
    </div>
  );
};

export default DataProvide;
