import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StateContext } from "../context";
import NavbarData from "./components/navbar_data";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { SearchButton } from "./SearchButton";

const NavBar = () => {
  const { fontSize } = useContext(StateContext);
  const { navbarOpened } = useContext(StateContext);
  const navigate = useNavigate();

  return (
    <nav className="navbar" style={{ height: parseInt(fontSize) - 30 }}>
      <div className="navbar-container">
        <div className="navbar-menus" style={{ left: navbarOpened ? 0 : 9999 }}>
          <NavbarData />
        </div>
        <SearchBar navigate={navigate} />
      </div>
    </nav>
  );
};

const SearchInput = ({
  placeholder,
  fontSize,
  includeRadioButtons = false,
  navigate,
  isFocused,
  setIsFocused,
}) => {
  const [, setParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const handleSearch = () => {
    if (searchText.length < 3) {
      setErrorMessage("minInputLength");
      return;
    }
    const tmpSearchText = searchText;
    // setSearchText("");
    setErrorMessage("");

    if (selectedValue === 2) {
      if (window.location.pathname === "/catalog") {
        setParams({ datasetName: tmpSearchText });
      } else {
        navigate(`/catalog?datasetName=${tmpSearchText}`);
      }
    }
    if (selectedValue === 1) {
      if (window.location.pathname === "/search") {
        setParams({ searchValue: tmpSearchText, page: 0 });
      } else {
        navigate(`/search?searchValue=${tmpSearchText}&page=0`);
      }
    }
  };
  return (
    <div
      onBlur={(a) => {
        // if no other element is focused, close the search box
        if (!a.relatedTarget) {
          setIsFocused(false);
        }
      }}
      onFocus={() => setIsFocused(true)} // Open the search box on focus
      className={includeRadioButtons || isFocused ? "searchbar-box" : "searchbar-box-closed"}
    >
      <div className={`searchbar-box-input-container ${errorMessage ? "red-outline" : ""}`}>
        <input
          type="text"
          className="search-modal-input-hovered"
          placeholder={placeholder}
          style={{ minHeight: parseInt(fontSize) * 0.5 + "px" }}
          value={searchText}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          onChange={(e) => {
            setSearchText(e.target.value);
            if (e.target.value.length === 0) {
              setErrorMessage("");
            }
          }}
        />
        <div className="navbar-searchbar-icon">
          <SearchButton
            tabIndex={0}
            className={errorMessage ? "c-error-stroke" : ""}
            hasText={!!searchText}
            onClick={() => {
              setSearchText("");
              setErrorMessage("");
            }}
          />
        </div>
      </div>
      {(includeRadioButtons || isFocused) && (
        <>
          {errorMessage && <span className="red-error-message">{t(errorMessage)}</span>}
          <div className="radio-container">
            <div
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              id="searchbox1"
              onClick={() => setSelectedValue(1)}
              tabIndex={-1}
            >
              <div
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSelectedValue(1);
                  }
                }}
                className="focus-red"
                style={{
                  display: "flex",
                  borderRadius: "50%",
                }}
              >
                <input
                  className="radio-item"
                  type="radio"
                  value="1"
                  name="group1"
                  checked={selectedValue === 1}
                  readOnly
                  tabIndex={-1}
                />
              </div>
              <div className="radio-text">
                <Trans i18nKey="allPlatform"></Trans>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              onClick={() => setSelectedValue(2)}
              tabIndex={-1}
            >
              <div
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSelectedValue(2);
                  }
                }}
                style={{
                  display: "flex",
                  borderRadius: "50%",
                }}
                className="focus-red"
              >
                <input
                  tabIndex={-1}
                  className="radio-item"
                  type="radio"
                  value="2"
                  name="group1"
                  checked={selectedValue === 2}
                  readOnly
                />
              </div>
              <div className="radio-text">
                <Trans i18nKey="inDataCatalog"></Trans>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className="search-modal-button focus-red"
                onClick={() => handleSearch()}
                style={{ minHeight: 25 + parseInt(fontSize) / 10, minWidth: parseInt(fontSize) }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              >
                <span>
                  <Trans i18nKey="search"></Trans>
                </span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SearchBar = ({ handleKeyDown, navigate }) => {
  const { fontSize } = useContext(StateContext);
  const { navbarOpened, setNavBarOpened } = useContext(StateContext);
  const [hovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        className="searchContainer"
        onBlur={(a) => {
          if (!a.relatedTarget || !a.currentTarget.contains(a.relatedTarget)) {
            setIsFocused(false);
          }
        }}
      >
        <SearchInput
          handleKeyDown={handleKeyDown}
          placeholder={t("searchPlaceHolder")}
          fontSize={fontSize}
          includeRadioButtons={hovered}
          navigate={navigate}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
        />
      </div>
      <button onClick={() => setNavBarOpened(!navbarOpened)} className="navbar-search-burger"></button>
    </>
  );
};

export default NavBar;
