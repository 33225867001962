import { useCallback, useEffect, useState } from "react";
import "./index.css";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { SearchButton } from "../SearchButton";

const CatalogSearch = ({ keywords, manualInput, setManualInput, placeholder }) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // filter
  const mouseover = (option) => {
    document.getElementById(`close${option}`).hidden = false;
  };

  const mouseleave = (option) => {
    document.getElementById(`close${option}`).hidden = true;
  };

  const handleKeywords = useCallback(() => {
    if (inputValue.length < 3) {
      setErrorMessage("minInputLength");
      return;
    }
    setManualInput(inputValue);
    setInputValue("");
    setErrorMessage("");
  }, [inputValue, setManualInput]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleKeywords();
      }
    },
    [handleKeywords]
  );

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.length === 0) setErrorMessage("");
  };

  useEffect(() => {
    if (manualInput) {
      setErrorMessage("");
    }
  }, [manualInput]);

  return (
    <div className="catalog-search" style={{ display: "flex", flexDirection: "column" }}>
      <div className="catalog-search-container">
        <div id="searchinput" className={`catalog-search-input ${errorMessage ? "red-outline" : ""}`}>
          <input
            value={inputValue}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className="catalog-search-input-text"
          />
          <div className="navbar-searchbar-icon">
            <SearchButton
              className={errorMessage ? "c-error-stroke" : ""}
              hasText={!!inputValue}
              onClick={() => {
                setErrorMessage("");
                setInputValue("");
              }}
            />
          </div>
        </div>
        <button
          type="button"
          className="catalog-search-button focus-red"
          onClick={() => {
            handleKeywords();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleKeywords();
            }
          }}
        >
          <div className="catalog-search-button-text">
            <Trans i18nKey="search" />
          </div>
        </button>
      </div>

      {errorMessage && (
        <span className="red-error-message" style={{ marginLeft: 40, width: "80vw" }}>
          {t(errorMessage)}
        </span>
      )}

      {keywords && (
        <div className="overall-dropdown-selection" style={{ marginLeft: 40, marginTop: 10 }}>
          {manualInput && (
            <label
              id={`label${manualInput}`}
              className="label focus-red"
              onMouseEnter={() => mouseover(manualInput)}
              onMouseLeave={() => mouseleave(manualInput)}
              tabIndex={0}
              onFocus={() => mouseover(manualInput)}
              onBlur={(e) => {
                e.relatedTarget?.id !== `close${manualInput}` && mouseleave(manualInput);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setManualInput("");
                }
              }}
            >
              <p className="font">{manualInput}</p>
              <div
                id={`close${manualInput}`}
                tabIndex={0}
                hidden={true}
                className="icon-close focus-red"
                onFocus={() => mouseover(manualInput)}
                onBlur={() => {
                  // does not close when switching to the label
                  if (!document.activeElement.id === `label${manualInput}`) {
                    mouseleave(manualInput);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setManualInput("");
                  }
                }}
              >
                <img
                  alt="remove input"
                  onClick={() => {
                    setManualInput("");
                  }}
                  src={"/assets/img/close.svg"}
                  style={{ height: "none" }}
                />
              </div>
            </label>
          )}
        </div>
      )}
    </div>
  );
};

export default CatalogSearch;
