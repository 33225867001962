const SmoothScrollLink = ({ href, children, style, className, onClick, id, preventScroll }) => {
  const handleClick = (event) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
    const targetElement = document.querySelector(href);
    if (!preventScroll) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
      const firstFocusable = targetElement.querySelector('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
      if (firstFocusable) {
        firstFocusable.focus();
      }
    }
  };

  return (
    <a
      href={href}
      id={id}
      className={`${className} focus-red`}
      onClick={handleClick}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleClick(event);
        }
      }}
      style={{
        ...style,
      }}
    >
      {children}
    </a>
  );
};

export default SmoothScrollLink;
