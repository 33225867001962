import { useNavigate } from "react-router-dom";
import { AuthBox, Banner } from "../common";
import { useTranslation } from 'react-i18next';
import { usr as user } from "../classes/user";
import { useEffect } from "react";

const useCardTranslations = () => {
  const { t } = useTranslation();
  const displayProfileName = user.displayProfileName() ? `, ${user.displayProfileName()}` : "";
  const bannerData = {
    title: t("menuGreeting") + displayProfileName,
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("menuProfileFeatures"),
    img: {
      href: "/assets/img/Sun.svg",
      className: "dashboard-topic-left-header-icon",
    },
  };

  const data = [
    {
      title: t('workspaceInbox'),
      text: t('workspaceViewInbox'),
      img: "/assets/img/mail1.svg",
      permissions: [
        "Paziņojumi administratoram iesūtnē",
        "Paziņojumi datu ņēmējiem",
        "Paziņojumi datu devējiem",
      ],
      url: "/messages"
    },
    {
      title: t('workspacePublishedData'),
      text: t('workspaceAddDataSets'),
      img: "/assets/img/wire.svg",
      permissions: [
        "Datu kopas augšupielāde",
        "Datu kopas publicēšanas pārtraukšana",
        "Datu kopas metadatu labošana, darbības ar datu kopu",
        "Datu devēja darba vieta",
        "Datu devēja publicētās datu kopas",
        "Datu kopas metadatu pievienošana",
        "Datu kopas publicēšanas pārtraukšana",
      ],
      url: "/manaskopas/list"
    },
    {
      title: t('workspaceCharts'),
      text: t('workspaceChartsDescription'),
      img: "/assets/img/earth.svg",
      permissions: [
        "Datu kopas augšupielāde",
        "Datu kopas publicēšanas pārtraukšana",
        "Datu kopas metadatu labošana, darbības ar datu kopu",
        "Datu devēja darba vieta",
        "Datu devēja publicētās datu kopas",
        "Datu kopas metadatu pievienošana",
        "Datu kopas publicēšanas pārtraukšana",
      ],
      url: "/reports"
    },
    {
      title: t('workspaceSubscribedData'),
      text: t('handleSubscribedData'),
      img: "/assets/img/wire.svg",
      permissions: [
        "Datu ņēmēja abonētās  datu kopas"
      ],
      url: "/abonetaskopas"
    },
    {
      title: t('workspaceDocumentation'),
      text: t('workspaceDocumentationInfo'),
      img: "/assets/img/dev.svg",
      permissions: ["API dokumentācija (datu devējam)", "API dokumentācija (datu ņēmējam)"],
      url: user?.can("API dokumentācija (datu devējam)") ? "/palidziba?currentPage=helpDD" : "/palidziba?currentPage=helpDN"
    },

    {
      title: t('workspaceDeclarations'),
      text: t('workspaceUploadDeclarations'),
      img: "/assets/img/markcheck.svg",
      permissions: ["Datu devēja apliecinājumi"],
      url: "/apliecinajumi"
    },
    {
      title: t('workspaceMyProfile'),
      text: t("workspaceHandleUserInfo"),
      img: "/assets/img/user.svg",
      permissions: [],
      url: "/profile"
    },
    {
      title: t('workspaceLogout'),
      text: t('workspaceLogoutDescription'),
      img: "/assets/img/cancel1.svg",
      permissions: [],
      url: "/logout"
    },
  ];
  return { bannerData, data };
};

const Item = ({ item, handleClick, index }) => {
  return (
    <div className="dashboard-main-form focus-red"
      onClick={() => handleClick(item.title)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleClick(item.title);
        }
      }}
      tabIndex={0}
    >
      <img alt="alt" className="dashboard-main-icon" src={item.img} />
      <span className="dashboard-main-title">{item.title}</span>
      <span className="dashboard-main-text">{item.text}</span>
    </div>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { bannerData, data } = useCardTranslations();
  //TODO saprast, kas notiek ar iziet
  const handleClick = (url, external = false) => {
    external ? window.location.href = url : navigate(url);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  let index = 0;
  return (
    <>
      <Banner bannerData={bannerData} children={<AuthBox />} />
      <div className="dashboard">
        <div className="dashboard-topic">
          <div className="dashboard-topic-left">
            <div className="dashboard-main">
              {data.map((item) => {
                const validPerm =
                  item?.permissions?.length === 0 ||
                  item?.permissions?.some((item) => user?.permissions.includes(item));
                if (validPerm) {
                  index++;
                  return (
                    <Item item={item} index={index} handleClick={() => handleClick(item.url, item?.external)} />
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default Dashboard;
