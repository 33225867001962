import moment from "moment";
import { useNavigate } from "react-router-dom";
import { postCms } from "../../../../classes/cmsApi";
import { Trans } from "react-i18next";
import { AbonetasKopasTableRow } from "./AbonetasKopasTable";
import { t } from "i18next";
import Tooltip from "../../../../common/atoms/tooltip";
import propTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../../../context";

export const statusStyle = (status) => {
  if (document.getElementById("body").className === "high-contrast") {
    return {};
  }

  switch (status) {
    case "publish":
      return { backgroundColor: "#33d751", color: "#ffffff" };
    case "edit":
      return { backgroundColor: "#4d4f50", color: "#ffffff" };
    case "submited":
      return { backgroundColor: "#4d4f50", color: "#ffffff" };
    case "deletable":
      return { backgroundColor: "#ffffff", color: "#9f1b33", border: "2px solid #9f1b33" };
    case "deleted":
      return { backgroundColor: "#9f1b33", color: "#ffffff" };
    default:
      return { backgroundColor: "#4d4f50", color: "#ffffff" };
  }
};

const EditButton = ({ item, onEditPress }) => {
  if (item.data_set_statuss !== "submited" && item.data_set_statuss !== "deletable") {
    return (
      <div
        className="manaskopas-main-tb1-tr2-td3-right-part1"
        onClick={onEditPress}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") onEditPress();
        }}
      >
        <img alt="alt" className="manaskopas-main-tb1-tr2-td3-right-part2-sub2" src="/assets/img/pen.svg" />
        <span className="manaskopas-main-tb1-tr2-td3-right-part2-font2">
          <Trans i18nKey="generalFix"></Trans>
        </span>
      </div>
    );
  }
  if (
    item.data_set_statuss === "submited" ||
    item.data_set_statuss === "publish" ||
    item.data_set_statuss === "deletable"
  ) {
    return <div>-</div>;
  }
  return null;
};

const PublishButton = ({ item, onPublishDataset }) => {
  if (
    item.data_set_statuss !== "submited" &&
    item.data_set_statuss !== "publish" &&
    item.data_set_statuss !== "deletable"
  ) {
    return (
      <button
        className="submit-button submit-button-font focus-red"
        onClick={onPublishDataset}
        style={{
          marginTop:
            item.data_set_statuss !== "submited" && item.data_set_statuss !== "deletable" ? "1em" : "",
        }}
      >
        <img
          alt=""
          className="rotate-45 filter-white"
          src="/assets/img/wi.svg"
          style={{ marginRight: "0.5em" }}
        />
        <Trans i18nKey="publishDataset"></Trans>
      </button>
    );
  }
  return null;
};

const TableRow = ({ item, onEditPress, onTitleClick, onPublishDataset, tableId }) => {
  const unixTimestamp = parseInt(item?.changed);
  const dateObject = new Date(unixTimestamp * 1000);
  const formattedDate = moment(dateObject).format("DD.MM.YYYY");
  const { language, fontSize } = useContext(StateContext);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const newErrors = [];
    const errorTitle = item.error_description ? item.error_description.split(":")[0] : t("validationError");

    if (errorTitle && item.error_description) {
      newErrors.push({
        title: errorTitle,
        description: item.error_description,
        isOpen: false,
      });
    }
    if (item.giver_key_inactive === "1") {
      newErrors.push({
        title: t("apiDeactivated"),
        description: t("giverKeyInactiveDescription"),
        isOpen: false,
      });
    }
    setErrors(newErrors);
  }, [item.error_description, item.giver_key_inactive, language]);

  return (
    <AbonetasKopasTableRow
      item={item}
      col1={
        <td className="manaskopas-td-container">
          <div className="manaskopas-main-tb1-tr2-td1 manaskopas-main-tb1-tr2-td1-dataset-name">
            <span
              onClick={onTitleClick}
              className="manaskopas-main-tb1-tr2-td1-font focus-red"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") onTitleClick();
              }}
            >
              {language === "en" ? item?.dataset_name : item?.dataset_name_lv}
              <img alt="alt" className="manaskopas-main-tb1-tr2-td1-warm1" src="/assets/img/link.svg" />
            </span>
          </div>
        </td>
      }
      col2={
        <td>
          {!["publish", "deletable"].includes(item.data_set_statuss) ? (
            <div className="manaskopas-main-tb2-tr2-td2">
              <button
                className="manaskopas-main-tb2-tr2-td2-btn"
                style={statusStyle(item.data_set_statuss)}
                tabIndex={-1}
              >
                <span className="manaskopas-main-tb2-tr2-td2-btn-font">
                  <b>{t(item.data_set_statuss).toLocaleUpperCase()}</b>
                </span>
              </button>
            </div>
          ) : (
            <div className="manaskopas-main-tb1-tr2-td2">
              <button
                className="manaskopas-main-tb1-tr2-td2-btn"
                style={statusStyle(item.data_set_statuss)}
                tabIndex={-1}
              >
                <span className="manaskopas-main-tb1-tr2-td2-btn-font">
                  <b>{t(item.data_set_statuss).toLocaleUpperCase()}</b>
                </span>
              </button>
            </div>
          )}
        </td>
      }
      col3={
        <td>
          <div className="manaskopas-main-tb1-tr2-td3 manaskopas-margin-x-2em">
            <div className="manaskopas-main-tb1-tr2-td3-left" style={{ minWidth: "6em" }}>
              <span>{formattedDate}</span>
            </div>
          </div>
        </td>
      }
      col4={
        <td style={{ height: errors.length <= 1 ? `100%` : `${errors.length * 4}em` }}>
          <div
            className="manaskopas-margin-x-2em manaskopas-center-y"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {errors.length ? (
              <>
                {errors.map((error, index) => (
                  <div
                    key={index + error.title}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "40%",
                      width: "100%",
                    }}
                  >
                    <div
                      className="tooltip-inside-table"
                      style={{
                        display: "flex",
                        flexDirection: fontSize === "100" ? "row" : "column",
                      }}
                    >
                      <div
                        className={`${
                          tableId === "published-table"
                            ? "manaskopas-error-title-9f1b33"
                            : "manaskopas-error-title-696969"
                        }`}
                        style={{
                          fontWeight: 500,
                          marginRight: "0.5em",
                          width: "10em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {error.title}
                      </div>
                      <Tooltip
                        customClass={"tooltip-text-inside-table"}
                        text={error.description}
                        onClickKeepOpen={true}
                      >
                        <div
                          className={`show-text authbox-arrow-${error.isOpen ? "open" : "closed"}`}
                          style={{ marginLeft: "0.5em", minWidth: "5em" }}
                          onClick={() => {
                            error.isOpen = !error.isOpen;
                            setErrors([...errors]);
                          }}
                        >
                          <Trans i18nKey="show" />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              "-"
            )}
          </div>
        </td>
      }
      col5={
        <td>
          <div className="manaskopas-main-tb1-tr2-td5">
            <EditButton item={item} onEditPress={onEditPress} />
            <PublishButton item={item} onPublishDataset={onPublishDataset} />
          </div>
        </td>
      }
    />
  );
};

export const TableHeadColumn = ({ children, colWidth, text, thClass }) => {
  return (
    <th className={thClass} style={{ width: colWidth }}>
      <div className="manaskopas-center-y">
        <div
          className="tooltip-inside-table"
          style={{
            width: "100%",
          }}
        >
          <span
            className="manaskopas-main-tb1-tr1-th1-font1"
            style={{ display: "flex", marginRight: "0.5em", alignItems: "center" }}
          >
            <div style={{ marginRight: "0.5em" }}>{text}</div>
            {children}
          </span>
        </div>
      </div>
    </th>
  );
};
TableHeadColumn.propTypes = {
  children: propTypes.node,
  colWidth: propTypes.string,
  text: propTypes.string,
  thClass: propTypes.string,
};

const Table = ({ data, toggleSubmit, onSort, errorBannerCallback, id }) => {
  const navigate = useNavigate();

  return (
    <>
      {data.title && (
        <div className="manaskopas-main-sub3" id={id} style={{ display: "flex", alignItems: "center" }}>
          <span className="manaskopas-main-sub3-font" style={{ marginRight: "0.5em" }}>
            {data.title}
          </span>
          {data.titleTooltipText && (
            <Tooltip
              text={data.titleTooltipText}
              customStyle={{ textWrap: "pretty", minWidth: "10vw" }}
              onClickKeepOpen={true}
            >
              <img alt="?" className="gray-question-mark" src="/assets/img/question.svg" />
            </Tooltip>
          )}
        </div>
      )}

      <div style={{ overflowX: "auto", borderRadius: 4 }}>
        <table className="manaskopas-main-tb1 fixed-table" id={id} style={{ height: "100%" }}>
          <thead>
            <tr className="manaskopas-main-tb1-tr1">
              <TableHeadColumn colWidth="30%" text={t("dataSet")} thClass="manaskopas-main-tb1-tr1-th1">
                <img
                  alt="sort"
                  className="manaskopas-main-tb1-tr1-th1-sort"
                  src="/assets/img/sort.svg"
                  onClick={onSort}
                />
              </TableHeadColumn>
              <TableHeadColumn colWidth="15%" text={t("status")}>
                <Tooltip text={t("statusTooltip")} customStyle={{ fontWeight: "400" }} onClickKeepOpen={true}>
                  <img alt="?" className="gray-question-mark" src="/assets/img/question.svg" />
                </Tooltip>
              </TableHeadColumn>
              <TableHeadColumn colWidth="15%" text={t("dataSetUpdated")}>
                <Tooltip
                  text={t("datasetUpdatedTooltip")}
                  customStyle={{ fontWeight: "400" }}
                  onClickKeepOpen={true}
                >
                  <img alt="?" className="gray-question-mark" src="/assets/img/question.svg" />
                </Tooltip>
              </TableHeadColumn>
              <TableHeadColumn colWidth="23%" text={t("warnings")}>
                <Tooltip
                  text={t("warningsTooltip")}
                  customStyle={{ fontWeight: "400" }}
                  onClickKeepOpen={true}
                >
                  <img alt="?" className="gray-question-mark" src="/assets/img/question.svg" />
                </Tooltip>
              </TableHeadColumn>
              <TableHeadColumn colWidth="17%" text={t("actions")}>
                <Tooltip
                  text={t("actionsTooltip")}
                  customStyle={{ fontWeight: "400" }}
                  onClickKeepOpen={true}
                >
                  <img alt="?" className="gray-question-mark" src="/assets/img/question.svg" />
                </Tooltip>
              </TableHeadColumn>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((item, index) => (
              <TableRow
                item={item}
                key={index}
                tableId={id}
                onEditPress={() => navigate("/manaskopas/" + item.nid)}
                onTitleClick={() => navigate("/card/" + item.uuid)}
                onPublishDataset={async () => {
                  let res = null;
                  try {
                    res = await postCms("api/v1/submit/review/metadata", { nid: item.nid });
                  } catch (error) {
                    if (error.error?.response_key) errorBannerCallback(error.error.response_key);
                  }
                  if (res?.message === "ok") {
                    toggleSubmit();
                  }
                }}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>   
  );
};

export default Table;
