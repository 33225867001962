import { sanitize } from "dompurify";
import { useNavigate } from "react-router-dom";

const MetadataRowFieldValue = ({ item, field, index }) => {
  const ellipsisTitles = ["Datu devējs", "Data publisher"];
  const isNarrowScreen = window.innerWidth < 1024;
  const isCategory = item.extra === "category";
  const hasHtml = item.html;

  const renderNarrowScreenContent = () => (
    <div className="card-content-detail-tab-tabpane-content-accordion-description-info-narrow-container">
      <span className="card-content-detail-tab-tabpane-content-accordion-description-info-narrow">
        {field}
      </span>
    </div>
  );

  const renderWideScreenContent = () => <span title={field}>{field}</span>;

  const renderDefaultContent = () => (
    <span
      className={isCategory ? "card-content-detail-tab-tabpane-content-accordion-description-info-cat" : ""}
      key={index}
      dangerouslySetInnerHTML={hasHtml ? { __html: sanitize(field) } : null}
    >
      {!hasHtml && field}
    </span>
  );

  if (ellipsisTitles.includes(item.title)) {
    return isNarrowScreen ? renderNarrowScreenContent() : renderWideScreenContent();
  }
  return renderDefaultContent();
};

const MetadataRows = ({ data, openTab=null }) => {
  const navigate = useNavigate();

  const openGiverWebsite = (url) => {
    const urlHasProtocol = url.startsWith("http://") || url.startsWith("https://");
    if (urlHasProtocol) {
      window.open(url, "_blank");
    }
    else {
      window.open(`https://${url}`, "_blank");
    }
  }

      

  return data.map((item, key) => {
    // if more styles are needed, make this in to a switch statement filtering by item.extra
    return (
      <tr key={key}>
        <th className="card-content-detail-tab-tabpane-content-accordion-description-label">
          <span>{item.title}</span>
        </th>
        <th className="card-content-detail-tab-tabpane-content-accordion-description-info">
          {item.fields.map((field, index) => {
            return (
              <>
                <MetadataRowFieldValue item={item} field={field} index={index} />
                {item.navigateTo && (
                  <div
                    className="dataset-link focus-red"
                    onClick={() => navigate(item.navigateTo)}
                    tabIndex={openTab === 1 ? 0 : -1}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") navigate(item.navigateTo);
                    }}
                  >
                    <span>{item.navigateToText}</span>
                  </div>
                )}
              </>
            );
          })}
          {item.navigateTo && !item.fields.length && (
            <div
              className="dataset-link focus-red"
              onClick={() => openGiverWebsite(item.navigateTo)}
              tabIndex={openTab === 1 ? 0 : -1}
              onKeyDown={(e) => {
                if (e.key === "Enter") openGiverWebsite(item.navigateTo);
              }}
            >
              <span>{item.navigateToText}</span>
            </div>
          )}
        </th>
      </tr>
    );
  });
};

export default MetadataRows;
