import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getCms } from "../../../classes/cmsApi";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import CatalogList from "../../catalog/components/catalogList";
import { StateContext } from "../../../context";
import { unquotify } from "../../search/search";

const getDataSets = async (language) => {
  try {
    const resp = await getCms("public/metadata/list", language, {}, 0);
    if (!resp || !resp.rows) return [];
    resp.rows = resp.rows.map((row) => {
      for (const key in row) {
        row[key] = unquotify(row[key]);
      }
      return row;
    });
    return resp.rows?.slice(0, 5) ?? [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

const Categories = () => {
  const { t } = useTranslation();
  const { language } = useContext(StateContext);

  const { data } = useQuery({
    queryKey: ["home-data-sets"],
    queryFn: () => getDataSets(language),
    refetchOnWindowFocus: false,
    placeholderData: [],
  });

  return (
    <div className="data-cat">
      <div className="data-cat-title"> {t("homeDataSets")}</div>
      <div className="datasets-home-page">
        <CatalogList data={data ?? []} />
        <LastItem />
      </div>
    </div>
  );
};

const LastItem = () => {
  const navigate = useNavigate();
  return (
    <div
      className="data-cat-construction focus-red"
      onClick={() => navigate("/catalog")}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") navigate("/catalog");
      }}
    >
      <div className="data-cat-construction-background">
        <div className="data-cat-construction-background-text">
          <Trans i18nKey="homeAllCategories"></Trans>
          <img alt="alt" className="data-cat-construction-background-text-icon" src="/assets/img/next.svg" />
        </div>
      </div>
    </div>
  );
};

export default Categories;
