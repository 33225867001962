import { useContext, useEffect } from "react";
import { Banner } from "../../common";
import Loader from "../../common/loader";
import { StateContext } from "../../context";
import IeladesModal from "../../modals/ielades/ieladesmodal";
import { ErrorMessage, ReloadPageButton } from "../manaskopas/components/errormessage";
import Table from "./components/table";
import { getCms } from "../../classes/cmsApi";
import { useTranslation, Trans } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";


const Button = ({ onClick }) => {
  return (
    <div className="manaskopas-main-sub5" style={{ display: "flex", justifyContent: "end" }}>
      <button onClick={onClick} className="manaskopas-main-sub5-btn5 focus-red" type="button">
        <img alt="alt" className="manaskopas-main-sub5-icon" src="/assets/img/add.svg" />
        <p className="manaskopas-main-sub5-font">
        <Trans i18nKey="uploadDeclaration"></Trans>
        </p>
      </button>
    </div>
  );
};

const useCertificatesData = () => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    [`certificates`],
    async () => {
      const res = await getCms("api/v1/get/owner/confirmation/list");
      let endObject = {
        active: [],
        inactive: [],
      };

      if (res.length) {
        res?.forEach((entry) => {
          const { value: statusValue } = entry.status[0];
          entry.status = statusValue;
  
          const { value: creationTimeValue } = entry.creation_time[0];
          entry.creation_time = creationTimeValue;
  
          if (entry.status === "1") {
            endObject.active.push(entry);
          } else {
            endObject.inactive.push(entry);
          }
        });
      }

      return endObject;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return { data, isLoading, isError, error, refetch };
};

const Apliecinajums = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError, error, refetch } = useCertificatesData();
  const { handleModalOpen } = useContext(StateContext);

  const bannerData = {
    title: t("workspaceDeclarations"),
    path1: t("workspaceStart"),
    path1Url: "/",
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
    path3: t("Declarations"),
    img: {
      href: "/assets/img/markcheck.svg",
      className: "apliecinajums-title-left-stitle-icon",
    },
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Banner bannerData={bannerData} />
      {isLoading && <Loader />}
      {isError && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <ErrorMessage text={error.error ?? t("dataFailedToLoad")} />
          <ReloadPageButton />
        </div>
      )}
      <Button onClick={() => handleModalOpen("ielades")} />
      <div style={{ margin: "5em 0" }}>
        <Table callback={refetch} data={data?.active} headers={[t("datasetAddDeclaration"), t("validFrom")]} />
        <IeladesModal callback={refetch} />
        <Table
          callback={refetch}
          data={data?.inactive}
          headers={[t("datasetAddDeclaration"), t("inactiveFrom")]}
        />
      </div>
    </>
  );
};

export default Apliecinajums;
